require("./foundation-datepicker");
require("./timepicker");

(function ($, document) {

    // Validators
    Foundation.Abide.defaults.validators['phone'] = phoneValidator;
    Foundation.Abide.defaults.validators['less_than_equal'] = lessThanEqualValidator;

    $(document).foundation();

    // Exit if the page does not have a time card
    if (typeof $('.time-card') === 'undefined') return;


    const SECONDS_IN_MINUTE = 60;
    const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
    const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;

    let defaults = {
        timeIn: '8:00am',
        timeOut: '4:30pm',
        lunchOut: '12:00pm',
        lunchIn: '12:30pm',
        paidTimeOff: 0
    };


    function WeekDay(element, day, options) {

        this.hours = 0;
        this.overtime = 0;
        this.regularHours = 0;
        this.pto = 0;
        this.total = 0;
        this.day = day;
        this.element = element;
        this.options = $.extend({}, defaults, options);

        this.timeParts = {
            enable: $('#' + day + 'Enable'),
            date: $('#' + day + 'Date'),
            inTime: $('#' + day + 'In'),
            outTime: $('#' + day + 'Out'),
            lunchOut: $('#' + day + 'LunchOut'),
            lunchIn: $('#' + day + 'LunchIn'),
            regularHours: $('#' + day + 'RegularHours'),
            regHours: $('#' + day + 'RegHours'),
            overtime: $('#' + day + 'OverHours'),
            pto: $('#' + day + 'PtoHours'),
            total: $('#' + day + 'Total'),
        };

        let inSettings = {
                defaultTime: this.options.timeIn,
                title: 'Time In',
                isBlankable: true,
                blankOnDisable: true
            },
            lunchOutSettings = {
                defaultTime: this.options.lunchOut,
                title: 'Lunch Out',
                minTime: this.options.timeIn,
                maxTime: this.options.timeOut,
                isClearable: true,
                isClear: true,
                blankOnDisable: true
            },
            lunchInSettings = {
                defaultTime: this.options.lunchIn,
                title: 'Lunch In',
                minTime: this.options.timeIn,
                maxTime: this.options.timeOut,
                isClearable: true,
                isClear: true,
                blankOnDisable: true
            },
            outSettings = {
                defaultTime: this.options.timeOut,
                title: 'Time Out',
                isBlankable: true,
                blankOnDisable: true
            };

        // noinspection JSUnresolvedFunction
        this.pickers = {
            inTime: this.timeParts.inTime.timepicker(inSettings).data('plugin_timepicker'),
            outTime: this.timeParts.outTime.timepicker(outSettings).data('plugin_timepicker'),
            lunchOut: this.timeParts.lunchOut.timepicker(lunchOutSettings).data('plugin_timepicker'),
            lunchIn: this.timeParts.lunchIn.timepicker(lunchInSettings).data('plugin_timepicker')
        };

        this.enable = false;
        this.timeParts.enable.prop('checked', false);
        this.enableDisableDay(false);

        this.timeParts.lunchIn.addClass('lin');
        this.timeParts.lunchOut.addClass('lout');

        this.timeParts.enable.on('change', $.proxy(this.onEnableChange, this));
        this.timeParts.inTime.on('change', $.proxy(this.onTimeChange, this));
        this.timeParts.outTime.on('change', $.proxy(this.onTimeChange, this));
        this.timeParts.lunchIn.on('change', $.proxy(this.onTimeChange, this));
        this.timeParts.lunchOut.on('change', $.proxy(this.onTimeChange, this));
        this.timeParts.overtime.on('change', $.proxy(this.onOvertimeChange, this));
        this.timeParts.pto.on('change', $.proxy(this.onPtoChange, this));

        this.compute();
    }

    $.extend(WeekDay.prototype, {

        onEnableChange: function() {
            let checkState = this.timeParts.enable.prop('checked');
            this.enableDisableDay(checkState);
        },

        enableDisableDay: function (isEnabled = true) {
            let timePickers = [this.pickers.inTime, this.pickers.outTime, this.pickers.lunchIn, this.pickers.lunchOut];

            this.enable = isEnabled;
            for (let picker of timePickers) {
                if (isEnabled) {
                    picker.enablePicker();
                } else {
                    picker.disablePicker();
                }
            }
            this.timeParts.pto.prop('disabled', !isEnabled);
            this.timeParts.overtime.prop('disabled', !isEnabled);

            this.update();
        },

        onPtoChange: function() {
            if (this.timeParts.pto.hasClass('is-invalid-input') || this.timeParts.pto.val() == '') {
                this.pto = 0;
            } else {
                this.pto = Number.parseFloat(this.timeParts.pto.val());
            }
            this.computeTotal();
            this.update();
        },

        onOvertimeChange: function() {
            if (this.timeParts.overtime.hasClass('is-invalid-input') || this.timeParts.overtime.val() == '') {
                this.overtime = 0;
            } else {
                this.overtime = Number.parseFloat(this.timeParts.overtime.val());
            }
            this.computeTotal();
            this.update();
        },

        onTimeChange: function (event) {
            if ($(event.target).hasClass('lin')) {
                if (this.pickers.lunchIn.isClear) {
                    this.pickers.lunchOut.clearPicker();
                } else {
                    this.pickers.lunchOut.unClearPicker();
                }
            }

            if ($(event.target).hasClass('lout')) {
                if (this.pickers.lunchOut.isClear) {
                    this.pickers.lunchIn.clearPicker();
                } else {
                    this.pickers.lunchIn.unClearPicker();
                }
            }

            let inTime = this.pickers['inTime'].getTime();
            let outTime = this.adjustForDay(inTime, this.pickers['outTime'].getTime());

            this.lunchRanges(inTime, outTime  % SECONDS_IN_DAY);

            this.compute();
        },

        compute: function () {
            let inTime = this.pickers['inTime'].getTime();
            let outTime = this.adjustForDay(inTime, this.pickers['outTime'].getTime());
            let lunchOut = this.adjustForDay(inTime, this.pickers['lunchOut'].getTime());
            let lunchIn = this.adjustForDay(inTime, this.pickers['lunchIn'].getTime());

            this.hours = ((outTime - inTime) - (lunchIn - lunchOut)) / SECONDS_IN_HOUR;
            this.timeParts.regHours.val(this.hours);
            this.computeTotal();
            this.update();

        },


        /*
         * Set the ranges that lunch time can have
         *
         * @param {integer} time of day that the shift starts
         * @param {integer} time of day that the shift ends
         */
        lunchRanges: function (inTime, outTime) {
            this.pickers['lunchOut'].setMinTime(inTime);
            this.pickers['lunchOut'].setMaxTime(outTime);
            this.pickers['lunchIn'].setMinTime(this.pickers['lunchOut'].getTime());
            this.pickers['lunchIn'].setMaxTime(outTime);
            this.pickers.lunchIn.updateFormUi();
            this.pickers.lunchOut.updateFormUi();
        },

        /*
         * Adjust time for day by comparing time against shift start time. If value is less than
         * shift start time then, we assume the shift goes through midnight so we add 24hours to it
         * so that it reflects that it is in the next day.  Thus for comparing purposes:
         *
         * timeIn <= LunchOut <= LunchIn <= timeOut, and a shift is less than 24hours long.
         */
        adjustForDay: function(inTime, seconds) {
            // extend times if needed. i.e. night shifts that cross midnight
            if (seconds == -1) return 0;
            return (seconds < inTime) ? seconds + SECONDS_IN_DAY : seconds;
        },

        computeTotal: function () {
            this.regularHours = this.hours - this.overtime;
            this.total = this.hours + this.pto;
        },

        update: function() {
            if (this.enable) {
                this.timeParts.regularHours.text(this.regularHours.toFixed(2));
                this.timeParts.total.text(this.total.toFixed(2));
            } else {
                this.timeParts.regularHours.text('');
                this.timeParts.total.text('');
            }
        },

        getDayValues: function() {
            return {
                'isEnable' : this.enable,
                'day' : this.day,
                'inTime' : this.pickers.inTime.getTime(),
                'outTime' : this.pickers.outTime.getTime(),
                'lunchIn' : this.pickers.lunchIn.getTime(),
                'lunchOut' : this.pickers.lunchOut.getTime(),
                'regular' : this.regularHours,
                'overtime' : this.overtime,
                'pto' : this.pto,
                'total' : this.total
            };
        }
    });

    let endOfWeek = $("#WeekEndDate"),
        dispRegularHours = $('#dispRegularHours'),
        dispOvertimeHours = $('#dispOvertimeHours'),
        dispPtoHours = $('#dispPtoHours'),
        dispTotalHours = $('#dispTotalHours'),
        regularHours = $('#regularHours'),
        overtimeHours = $('#overtimeHours'),
        ptoHours = $('#ptoHours'),
        totalHours = $('#totalHours');


    endOfWeek.fdatepicker({
        initialDate: Date(),
        format: 'mm-dd-yyyy',
        disableDblClickSelection: true,
        leftArrow:'<<',
        rightArrow:'>>',
        closeIcon:'X',
        closeButton: true,
        weekStart: 1,
        calendarWeeks: false,
        weekPick: true
    });

    // noinspection JSUnusedLocalSymbols
    function lessThanEqualValidator($el, required, parent) {
        if (!required) {
            if ($el.val() == '') {
                $el.val($el.data('leq-default') ? $el.data('leq-default') : 0);
                return true;
            }
        }
        console.log('data-less-than-equal: ' + $el.attr('data-less-than-equal'));
        let from = $('#' + $el.attr('data-less-than-equal')).val(),
            to = $el.val();
        return (parseFloat(to) <= parseFloat(from));
    }

    // noinspection JSUnusedLocalSymbols
    function phoneValidator($el, required, parent) {
        if (!required && $el.val() == '') return true;

        let pattern = /^(\s|[(])*([0-9]{3})(\s|[)-])*([0-9]{3})(\s|[-])*([0-9]{4})\s*$/;
        let match = $el.val().match(pattern);
        if (match == null) return false;
        let areaCode = match[2];
        let prefix = match[4];
        let number = match[6];
        $el.val('(' + areaCode + ') ' + prefix + ' - ' + number);
        return true;
    }

    let dayTimes = [];
    let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    for (let day of days) {
        let dayelem = $('#' + day);
        let daytime = new WeekDay(dayelem, day);
        dayelem.data('weekday', daytime);
        dayTimes.push(daytime);
    }

    $('#time-sheet').data('time-sheet-times', dayTimes);

    let datepicker = endOfWeek.data('datepicker');
    function onTimeChange() {
        let regular = 0,
            overtime = 0,
            pto = 0,
            total = 0;
        for (let dayTime of dayTimes) {
            if (dayTime.enable) {
                regular += dayTime.regularHours;
                overtime += dayTime.overtime;
                pto += dayTime.pto;
                total += dayTime.total;

            }
        }

        if (regular > 40) {
            let extraOvertime = regular % 40;
            overtime += extraOvertime;

            regular = 40;

        }

        dispRegularHours.text(regular.toFixed(2));
        regularHours.val(regular);

        dispOvertimeHours.text(overtime.toFixed(2));
        overtimeHours.val(overtime);

        dispPtoHours.text(pto.toFixed(2));
        ptoHours.val(pto);

        dispTotalHours.text(total.toFixed(2));
        totalHours.val(total);

        updateWeekdayOut();
    }

    function updateWeekdayOut() {
        let dayIndex = {
            'sunday' : 0,
            'monday' : 1,
            'tuesday' : 2,
            'wednesday' : 3,
            'thursday' : 4,
            'friday' : 5,
            'saturday' : 6
        };

        for (let dayTime of dayTimes) {
            let day = dayTime.getDayValues();
            let outId = '#' + day.day + '_out';
            let dayOut = day.isEnable ? 'true,' : 'false,';
            dayOut += (datepicker.getDayOfWeekDate(dayIndex[day.day], false).getTime() / 1000) + ',';
            dayOut += day.inTime + ',';
            dayOut += day.outTime + ',';
            dayOut += day.lunchOut + ',';
            dayOut += day.lunchIn + ',';
            dayOut += day.regular + ',';
            dayOut += day.overtime + ',';
            dayOut += day.pto + ',';
            dayOut += day.total;
            $(outId).val(dayOut);
        }
    }

    $(document).on('change', onTimeChange);

    function setDates() {
        $("#mondayDate").text(datepicker.getDayOfWeekDate(1));
        $("#tuesdayDate").text(datepicker.getDayOfWeekDate(2));
        $("#wednesdayDate").text(datepicker.getDayOfWeekDate(3));
        $("#thursdayDate").text(datepicker.getDayOfWeekDate(4));
        $("#fridayDate").text(datepicker.getDayOfWeekDate(5));
        $("#saturdayDate").text(datepicker.getDayOfWeekDate(6));
        $("#sundayDate").text(datepicker.getDayOfWeekDate(0));

        /* Put date into hidden field for posting in secs instead of milliseconds. */
        $("#week_end_out").val(datepicker.getUTCDate().getTime() / 1000);
    }
    if (typeof datepicker!== 'undefined') {
        endOfWeek.on('changeDate', setDates);
        setDates();
    }

})(jQuery, document);